import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./HomePage";
import RegistrationPage from "./RegisterPage";

export const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<HomePage />} />
        {/* <Route path="/register" element={<RegistrationPage />} /> */}
      </Routes>
    </Router>
  );
};
