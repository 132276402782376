import { useState, useEffect, useRef } from "react";
// import earth from "../media/earth_cmp.mp4";
import { FiArrowUpRight } from "react-icons/fi"; // Import the arrow icon
import { Link } from "react-router-dom";
import { ShootingStars } from "../Components/ui/shooting-stars";
import { StarsBackground } from "../Components/ui/stars-background";

function Main() {
  const [scrollY, setScrollY] = useState(0);

  // Function to handle scroll event
  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      // Set the playback rate to slow down the video
      videoRef.current.playbackRate = 0.7; // 0.5 is half speed; 1 is normal speed
    }
  }, []);

  // Calculate horizontal translation based on scroll position
  const translateX = Math.max(-100, scrollY * 2); // Translate left as you scroll down

  return (
    <section className="top-0 left-0 h-[90vh] overflow-hidden ">
      <div
        style={{
          position: "relative",
          width: "100vw", // Full width of the viewport
          height: "100vh", // Full height of the viewport
          overflow: "hidden", // Hide the extra part
        }}
      >
        {/* Set z-index to -1 for ShootingStars and StarsBackground */}
        {/* <ShootingStars style={{ zIndex: -1 }} />
        <StarsBackground style={{ zIndex: -1 }} /> */}
        {/* <video
          ref={videoRef}
          autoPlay
          muted
          loop
          style={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translateX(-50%) rotate(-90deg)", // Rotate 90 degrees and center horizontally
            height: "100vw", // Maintain aspect ratio
            objectFit: "cover", // Ensures the video fills the container, maintaining aspect ratio
          }}
          bg-blend-darken
        >
          <source src="{earth}" type="video/mp4" />
        </video> */}
        {/* Use img tag to display the GIF */}
        {/* <img
          src={earth}
          alt="Earth"
          style={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translateX(-50%) rotate(-90deg)", // Rotate 90 degrees and center horizontally
            height: "100vw", // Maintain aspect ratio
            objectFit: "cover", // Ensures the video fills the container, maintaining aspect ratio
            zIndex: 20, // Behind other content
            opacity: 0.5, // Adjust opacity for visibility
          }}
        /> */}

        {/* Black fade overlay for the video */}
        <div className="absolute bottom-0 left-0 right-0 h-1/6 bg-gradient-to-t from-black to-transparent" />

        <div className="flex flex-col justify-center items-center bg-custom bg-center bg-no-repeat bg-cover px-4 mx-auto max-w-screen-3xl text-center w-screen h-screen py-24 lg:py-56 relative z-20">
          <h4
            className="text-lg text-white"
            style={{
              transform: `translateX(-${translateX}px)`,
              transition: "transform 0.1s ease-out", // Smooth transition for better effect
            }}
          >
            HOPEFULLY{" "}
            <span className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
              ONE DAY
            </span>{" "}
            WE WILL BE BACK
          </h4>
          <br />
          <h1
            className="mix-blend-exclusion text-[18vw] mb-4 font-extrabold tracking-widest leading-none text-white sm:text-8xl md:text-8xl lg:text-9xl"
            style={{
              transform: `translateX(${translateX}px)`,
              transition: "transform 0.1s ease-out", // Smooth transition for better effect
            }}
          >
            SOMETIMES
          </h1>
          <h1
            className="mix-blend-exclusion mb-4 font-extrabold tracking-widest leading-none text-white text-[10vw] sm:text-[5vmax] lg:text-5xl"
            style={{
              transform: `translateX(-${translateX}px)`,
              transition: "transform 0.1s ease-out", // Smooth transition for better effect
            }}
          >
            LIFE HAPPENS
          </h1>
          <br />
          {/* <Link to="/register"> */}
          {/* <div
            // type="button"
            className="relative group flex text-lg items-center justify-start focus:outline-none font-medium rounded-full px-4 py-2 space-x-2
              bg-gradient-to-r from-[#00e0ff] via-[#7000ff] to-[#ff6868] text-white z-20"
            style={{
              transform: `translateX(${translateX}px)`,
              transition: "transform 0.1s ease-out", // Smooth transition for better effect
            }}
          >
            <span>See Ya :)</span>
            <span className="inline-block rounded-md">
              <FiArrowUpRight className="w-6 h-6 text-white" />
            </span>
          </div> */}
          {/* </Link> */}
          <a href="mailto:info@starter101.com">
            <div className="relative inline-flex h-9 sm:h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 transition duration-300 ease-in-out hover:shadow-lg hover:bg-slate-800">
              <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#00e0ff_0%,#7000ff_25%,#ff6868_50%,#7000ff_75%,#00e0ff_100%)]" />
              <span className="inline-flex h-full w-full  items-center justify-center rounded-full bg-slate-950 px-2 sm:px-3 sm:py-1 text-md sm:text-lg font-medium text-white backdrop-blur-3xl transition duration-300 ease-in-out hover:shadow-md">
                See Ya :)
              </span>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Main;
